import React from 'react'
import { css } from '@emotion/react'
import { rhythm, MOBILE_MEDIA_QUERY } from '../utils/typography'
import Typed from './Typed'

const PostTitle = ({ children }) => (
  <Typed
    ele="h1"
    css={css`
      margin-bottom: ${rhythm(-3 / 8)};
      ${MOBILE_MEDIA_QUERY} {
        margin-right: 36px;
      }
    `}
  >
    {children}
  </Typed>
)

export default PostTitle
